<template>
  <Popup popupType="printType">
    <div class="section_comm">
      <div class="body_section">
        <div class="box_print">
          <div id="wrapPrint" class="wrap_print">
            <PrintPage>
              <template v-slot:head>
                <PrintHeader type="견적" :itemData="itemData" />
              </template>
              <template v-slot:content>
                <div v-if="itemData.quotation">
                  <PrintInfo type="견적" :itemData="itemData.quotation" />
                  <PrintGoodsDetail :itemData="itemData.quotation" />
                  <PrintEtc :etcData="itemData.quotation.contents" />
                </div>
                <div v-else>
                  <PrintEstimateContent :itemData="itemData" />
                </div>
              </template>
            </PrintPage>
          </div>
        </div>
      </div>
    </div>
    <Sticky class="sticky">
      <button type="button" class="btn_tertiary btn_medium" @click="onClickClose">닫기</button>
      <!--  v-if="isPrintAble"  @click="print" -->
      <!-- 발주 완료 된 발주서면 v-if -->
    </Sticky>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import PrintPage from "@/components/shared/print/PrintPage";
import PrintHeader from "@/components/shared/print/PrintHeader";
import PrintInfo from "@/components/shared/print/PrintInfo";
import PrintGoodsDetail from "@/components/shared/print/PrintGoodsDetail";
import PrintEtc from "@/components/shared/print/PrintEtc";
import PrintEstimateContent from "@/components/shared/print/PrintEstimateContent.vue";

import Sticky from "@/components/layout/content/Sticky.vue";
import { getMoneyToKorean, addCommaForMoney, getMoneyToNumber } from "@/utils/stringUtils";

import { Printd } from "printd";
import printcss from "@/constants/printcss.js";

import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  components: {
    Popup,
    PrintPage,
    PrintHeader,
    PrintInfo,
    PrintGoodsDetail,
    PrintEtc,
    PrintEstimateContent,
    Sticky,
  },
  props: {
    estimateNo: String,
    prchsrMngrNm: String,
  },
  data() {
    return {
      itemData: {},
      canWriteOrder: false,
    };
  },

  // computed: {
  //   canWriteOrder() {
  //     const { statusCode } = this.itemData;
  //     if( !this.itemData || statusCode == undefined ) return false;
  //     // 견적서의 상태가 없을 때만, 발주 등록 버튼 노출.
  //     // 임시 저장 상태에도 노출하려면, 발주 번호 등 다른 정보도 필요.
  //     return !statusCode || statusCode === 'C';
  //   },
  // },
  watch: {
    // itemData() {
    //   const { statusCode } = this.itemData;
    //   const result = Boolean(!statusCode || statusCode === 'C');
    //   // 견적서의 상태가 없을 때만, 발주 등록 버튼 노출.
    //   // 임시 저장 상태에도 노출하려면, 발주 번호 등 다른 정보도 필요.
    //   this.canWriteOrder = result;
    // },
  },
  mounted() {
    this.getData(this.estimateNo);
  },
  methods: {
    async getData(id) {
      const path = `${this.$apiPath.ESTIMATE}/${id}`;
      const token = LocalStorageManager.shared.getTokenData();
      const result = await ApiService.shared.getData(path);
      const { code, data, message } = result;
      this.itemData = data;
    },
    print() {
      const wrapPrint = document.getElementById("wrapPrint");
      const d = new Printd();

      // printd 라이브러리 버그로 인해 그냥 스트링을 가져오는 걸로 임시 처리. 추후 변경 필요.
      // 버그 내용: css를 읽었다 안읽었다 한다.
      // const styles = [
      //   '/assets/css/print.css'
      // ]

      const styles = [printcss];

      d.print(wrapPrint, styles);
    },
    onClickClose() {
      this.$emit("onClickClose");
    },
    onClickOrder() {
      this.$router.push({
        name: this.$routerPath.ORDER_WRITE,
        params: {
          estimateData: this.itemData,
          estimateReqNo: this.$route.params.id,
          prchsrMngrNm: this.prchsrMngrNm,
        },
      });
    },
  },
};
</script>

<style scoped>
@import "~@/assets/css/print.css";
.page_print + .page_print {
  border-top: 1px solid #e1e1e1;
}
</style>
