<template>
  <!-- 상단 고정 발주 번호 -->
  <div class="head_print">
    <div class="area_number">
      <div class="badge_number">
        <strong class="tit_number">{{ type }}서번호</strong>
        <span class="txt_number">{{
          itemData.quotationNum ? itemData.quotationNum : itemData.estimateNum
        }}</span>
      </div>
    </div>
    <span class="txt_print">{{ type }}서</span>
    <h1 class="tit_print" v-html="itemData.title" />
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    itemData: Object,
  },
};
</script>
