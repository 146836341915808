<template>
  <!-- itemData.estimateGoodsList -->
  <tr>
    <td>{{ no + 1 }}</td>
    <td>{{ item.itemName }}</td>
    <td>
      <InputNumber
        :value.sync="stringQuantity"
        data-cy="itemQuantity"
        @update:value="onUpdateCount"
      />
    </td>
    <td>
      <InputAutoMoney
        :value.sync="moneyTypePrice"
        data-cy="itemPrice"
        @update:value="onUpdateCount"
      />
    </td>
    <td>
      <Input :value.sync="item.supplyPrice" :isDisabled="true" @update:value="onUpdateCount" />
    </td>
    <td>
      <Input :value.sync="item.tax" :isDisabled="true" @update:value="onUpdateCount" />
    </td>
  </tr>
</template>

<script>
import Input from "@/components/common/input/Input";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import InputNumber from "@/components/common/input/InputNumber";
import EstimateGoodsWriteItemModel from "./EstimateGoodsWriteItemModel";

import { getMoneyToNumber } from "@/utils/stringUtils";
import { MAX_LENGTH_QUANTITY, MAX_LENGTH_UNIT_PRICE } from "@/constants/commonTextLength";

export default {
  name: "EstimateGoodsWriteItem",
  components: {
    Input,
    InputAutoMoney,
    InputNumber,
  },
  props: {
    no: Number,
    isTaxApply: Boolean,
    item: Object,
    canModifyItemPrice: Boolean,
    canRemove: Boolean,
    canModifyItem: Boolean,
  },
  data() {
    return {
      //itemModel: new EstimateGoodsWriteItemModel(),
      stringQuantity: String(this.item.quantity),
      moneyTypeTax: String(this.item.tax),
      moneyTypePrice: String(this.item.price),
      moneyTypeSupplyPrice: String(this.item.supplyPrice),
      itemTax: this.item.tax,
    };
  },
  computed: {
    isDisabledQuantity() {
      const { possibleQuantity } = this.item.objRaw;

      if (!possibleQuantity) {
        return false;
      }

      return possibleQuantity < 2;
    },
    MAX_LENGTH_QUANTITY() {
      return MAX_LENGTH_QUANTITY;
    },
    MAX_LENGTH_UNIT_PRICE() {
      return MAX_LENGTH_UNIT_PRICE;
    },
  },
  watch: {
    itemTax(newVal) {},
  },
  mounted() {
    // 값과 함께 생성 된 경우 계산 필요.
    this.onUpdateCount();
  },
  methods: {
    onUpdateCount(newValue) {
      const supplyPrice = Number(this.stringQuantity) * getMoneyToNumber(this.moneyTypePrice);
      this.item.supplyPrice = String(getMoneyToNumber(supplyPrice));
      this.item.tax = String(getMoneyToNumber(supplyPrice * 0.1));

      this.item.price = getMoneyToNumber(this.moneyTypePrice);
      this.$emit("onUpdateCount");
    },
  },
};
</script>
