<template>
  <div class="area_print area_info">
    <div class="info_left">
      <span class="bar_info" />
      <!-- <dl>
        <dt>공급사</dt>
        <dd>{{ itemData.companyName }}</dd>
      </dl> -->
      <dl>
        <dt>견적일자</dt>
        <dd>{{ itemData.regDate | dateStringFormat }}</dd>
      </dl>
      <!-- <dl>
        <dt>사업자 번호</dt>
        <dd>{{ itemData.corporateNum }}</dd>
      </dl>-->
      <dl>
        <!-- <dt>공급사담당자/회사전화번호</dt>
        <dd>{{ itemData.name }}</dd>
        <dd>{{ itemData.tel }}</dd> -->
      </dl>
    </div>
    <div class="info_right">
      <div class="box_total">
        <div class="head_total">
          <strong class="tit_total">{{ type }}금액</strong>
          <span class="txt_total">
            <template v-if="itemData.supplyPriceTotal == 0"> 공급가액 </template>
            <template v-else> 공급가액+세액 </template>
          </span>
        </div>
        <div class="body_total">
          <span class="txt_total">일금</span>
          <em class="txt_price">{{ sumKorean }}원정</em>
          <span class="num_price">({{ itemData.quotationPriceTotal | currency }})</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMoneyToKorean } from "@/utils/stringUtils";

export default {
  props: {
    type: String,
    itemData: Object,
  },
  computed: {
    sumKorean() {
      const { quotationPriceTotal } = this.itemData;

      if (!quotationPriceTotal) return "";

      return getMoneyToKorean(quotationPriceTotal);
    },
  },
};
</script>
