<template>
  <div class="page_print">
    <slot name="head" />
    <div class="cont_print">
      <slot name="content" />
    </div>
    <!-- <slot name="footer" /> -->
  </div>
</template>

<script>
export default {
  name: "PrintPage",
  // ,
  // props: {
  //   isShow:Boolean
  // }
};
</script>
