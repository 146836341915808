<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">견적관리</h2>
      </div>
      <div class="body_section">
        <Fold title="기본정보">
          <TableView>
            <template v-slot:body>
              <tr>
                <th>견적요청번호</th>
                <td>
                  <div class="row_pack">
                    <span class="txt_view">{{ model.estimateNum }}</span>
                    <div class="area_right">
                      <span :class="['badge_state']">{{ model.statusName }}</span>
                    </div>
                  </div>
                </td>
                <th>견적요청일자</th>
                <td>{{ model.reqDate | dateStringFormat }}</td>
              </tr>
              <tr>
                <th>구매사담당자</th>
                <td>{{ model.SpprName }}</td>
                <th>견적요청작성자</th>
                <td>{{ model.reqName }}</td>
              </tr>
              <tr>
                <th>견적요청명</th>
                <td>{{ model.reqTitle }}</td>
                <th>이전견적</th>
                <td>
                  <span :class="'link_subject'" @click="onClickPopEstimatePrint(model)">
                    {{ model.upTitle }}
                  </span>
                </td>
              </tr>
            </template>
          </TableView>
        </Fold>

        <Fold title="견적상세품목">
          <TableView>
            <template v-slot:colgroup>
              <!-- No -->
              <col style="width: 62px" />
              <!-- 품명 -->
              <col />
              <!-- 수량 -->
              <col style="width: 138px" />
              <!-- 단가 -->
              <col style="width: 150px" />
              <!-- 공금가액 -->
              <col style="width: 150px" />
              <!-- 세액 -->
              <col style="width: 150px" />
            </template>
            <template v-slot:header>
              <th scope="col">No.</th>
              <th scope="col">품명</th>
              <th scope="col">수량</th>
              <th scope="col">단가</th>
              <th scope="col">공급가액</th>
              <th scope="col">세액</th>
            </template>
            <template v-slot:body>
              <template v-for="(item, idx) in model.quotationItemList">
                <EstimateGoodsWriteItem
                  :key="idx"
                  :no="idx"
                  :item="item"
                  :isTaxApply="model.taxYn"
                  @onUpdateCount="onUpdateTotalCount"
                />
              </template>
            </template>
          </TableView>
          <TableView>
            <template v-slot:body>
              <tr>
                <th>세액</th>
                <td>
                  <button v-if="!model.taxYn" class="btn_small btn_fourthly" @click="onApplyTax">
                    세액 적용
                  </button>
                  <button v-else class="btn_small btn_fourthly" @click="onDisapplyTax">
                    세액 적용 취소
                  </button>
                </td>
                <td colspan="2" rowspan="2" class="no_padding">
                  <dl class="list_total">
                    <dt>공급가액</dt>
                    <dd>₩ {{ totalSupplyCount | currency }}</dd>
                    <dt>할인</dt>
                    <dd>(-) ₩ {{ isDiscountApply ? onUpdateDiscountPrice() : 0 | currency }}</dd>
                    <dt>세액</dt>
                    <dd>₩ {{ model.taxYn ? totalTaxCount : 0 | currency }}</dd>
                    <dt class="dt_total">
                      견적금액 <span class="txt_small">(공급가액+세액)</span>
                    </dt>
                    <dd class="dd_total">
                      <span class="txt_total">일금</span
                      ><span class="txt_korean">
                        {{ priceToKorean ? `${priceToKorean}원정` : "" }}</span
                      ><span class="txt_price">(₩ {{ quotationPriceTotal | currency }})</span>
                    </dd>
                  </dl>
                </td>
              </tr>
              <tr>
                <th>할인</th>
                <td>
                  <button
                    v-if="!isDiscountApply"
                    class="btn_small btn_fourthly"
                    @click="onApplyDiscount"
                  >
                    할인 적용
                  </button>
                  <button v-else class="btn_small btn_fourthly" @click="onDisapplyDiscount">
                    할인 미적용
                  </button>
                  <InputNumber
                    :isDisabled="isDiscountApply ? true : false"
                    :value.sync="stringDiscountRate"
                  />
                  <span>%</span>
                </td>
              </tr>
            </template>
          </TableView>
        </Fold>

        <Fold title="상세내용">
          <TableView>
            <template v-slot:body>
              <tr>
                <th>상세내용</th>
                <td colspan="3" v-html="SpprContents" />
              </tr>
            </template>
          </TableView>
        </Fold>

        <Fold title="견적서">
          <TableView>
            <template v-slot:body>
              <tr>
                <th>견적서번호</th>
                <td>{{ model.itemId || "-" }}</td>
                <!-- <th>견적서명 <span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <Input
                    placeholder="견적요청명을 입력하세요."
                    :maxLength="MAX_LENGTH_TITLE"
                    :value.sync="model.title"
                  />
                </td> -->
                <th>견적일자</th>
                <td>
                  {{ model.reqDate | dateStringFormat }}
                  <!-- <Input
                    placeholder="견적요청명을 입력하세요."
                    :maxLength="MAX_LENGTH_TITLE"
                    :value.sync="model.title"
                  /> -->
                  <!-- <DateTimePicker :date.sync="model.estimateDate" /> -->
                </td>
              </tr>
              <tr>
                <th>파트너사</th>
                <td>{{ model.companyName || "-" }}</td>
                <th>사업자등록번호</th>
                <td>{{ model.username || "-" }}</td>
              </tr>
              <tr>
                <th>파트너사 담당자</th>
                <td>
                  <Input placeholder="담당자명을 입력하세요." :value.sync="model.quotationName" />
                </td>
                <th>회사전화번호</th>
                <td>
                  <InputPhone
                    :useMobile="false"
                    :useArea="true"
                    :phone0.sync="model.tel.phone0"
                    :phone1.sync="model.tel.phone1"
                    :phone2.sync="model.tel.phone2"
                  />
                </td>
              </tr>
            </template>
          </TableView>
        </Fold>

        <Fold title="비고">
          <TableView>
            <template v-slot:body>
              <tr>
                <th>비고</th>
                <td style="height: 200px" colspan="3">
                  <Textarea
                    placeholder="비고"
                    :isCount="true"
                    :value.sync="model.quotationContents"
                  />
                </td>
              </tr>
            </template>
          </TableView>
        </Fold>
      </div>
    </div>

    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_tertiary btn_large" @click="onClickCancelConfirm">
          취소
        </button>
      </div>
      <div class="area_right">
        <!-- <template v-if="isModifyMode">
          <button type="button" class="btn_secondary btn_large" @click="onClickRemoveConfirm">
            삭제
          </button>
          <button type="button" class="btn_primary btn_large" @click="onClickUpdateConfirm">
            수정
          </button>
        </template> -->
        <template>
          <button type="button" class="btn_secondary btn_large" @click="onClickTempConfirm">
            임시저장
          </button>
          <button type="button" class="btn_primary btn_large" @click="onClickSaveConfirm">
            발송
          </button>
        </template>
      </div>
    </Sticky>
    <EstimatePrintPopup
      v-if="isPopEstimatePrint"
      :estimateNo="estimatePrint ? estimatePrint.estimateNum : null"
      @onClickClose="onClickClosePopEstimatePrint"
    />
    <template v-slot:popup>
      <AnnouncePartnerPopup
        v-if="isPopPartners"
        @onClickClose="onClickClosePopPartner"
        @onClickAdd="onClickAddPopPartner"
      />

      <AlertPopup
        v-if="isSaveConfirm"
        alertText="견적을 발송하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertConfirm"
        @onClickPrimary="saveEstimate"
      />
      <AlertPopup
        v-if="isUpdateConfirm"
        alertText="견적을 수정하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertConfirm"
        @onClickPrimary="saveEstimate"
      />
      <AlertPopup
        v-if="isTempConfirm"
        alertText="견적을 임시서장하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertConfirm"
        @onClickPrimary="saveEstimate"
      />
      <AlertPopup
        v-if="isAlertCancel"
        alertText="견적 작성을 취소하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertConfirm"
        @onClickPrimary="onClickGoToList"
      />

      <!-- <ClientStraightPopup
        v-if="isPopClientStraight"
        :clientData="clientData"
        @onClickClose="onClickCloseClientStraightPop"
        @setData="onClickSupplySearch"
      />
      <ScorePopup
        v-if="isPopScore"
        :itemData="companyCreditData"
        @onClickClose="onClickCloseScorePop"
      />
      <CatalogListPopup
        v-if="selectedCompany"
        v-show="!selectedCatalog"
        :companyNo="
          selectedCompany
            ? selectedCompany.spplrCompanyRgstNo || selectedCompany.clientCompanyRgstNo
            : null
        "
        @onClickCatalogLine="onClickCatalogLine"
        @onClickClose="onClickCloseCatalogList"
      />
      <CatalogDetailPopup
        v-if="selectedCompany && selectedCatalog"
        :brochureSeq="selectedCatalog.brochureSeq"
        @onClickClose="onClickCloseCatalogDetail"
      /> -->
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";

import TableView from "@/components/shared/tableView/TableView";
import Fold from "@/components/common/fold/Fold";
import Board from "@/components/common/board/Board";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";
import Input from "@/components/common/input/Input";
import InputPhone from "@/components/common/input/InputPhone";
import InputNumber from "@/components/common/input/InputNumber";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import Radio from "@/components/common/radio/Radio";
import Textarea from "@/components/common/textarea/Textarea";
import Sticky from "@/components/layout/content/Sticky.vue";
import ClientStraightPopup from "@/components/layout/popup/ClientStraightPopup";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import ScorePopup from "@/components/admin/estimate/popup/ScorePopup";
import CatalogListPopup from "@/components/admin/estimate/popup/CatalogListPopup";
import CatalogDetailPopup from "@/components/admin/estimate/popup/CatalogDetailPopup";

import RequestPartnersInfo from "@/components/admin/announce/write/RequestPartnersInfo";
import AnnouncePartnerPopup from "@/components/admin/announce/popup/AnnouncePartnerPopup";

import WritePageMixin from "@/mixins/WritePageMixin";
import CreditMixin from "@/mixins/CreditMixin";
import PopCatalogMixin from "@/mixins/estimate/PopCatalogMixin";
import ClientStraightMixin from "@/mixins/ClientStraightMixin";
import PageMixin from "@/mixins/PageMixin";

import ApiService from "@/services/ApiService";
import { mapState } from "vuex";
import { ALERT_ACTION } from "@/store/modules/alert/action";
import { GET_ITEM_CATEGORY_ACTION } from "@/store";

import { getMoneyToNumber, addCommaForMoney, getMoneyToKorean } from "@/utils/stringUtils";
import { dateToString } from "@/utils/dateUtils";
import { assign } from "@/utils/arrayUtils";

import EstimateModel from "./EstimateModel";
import EstimateGoodsWriteItemModel from "./EstimateGoodsWriteItemModel";
import EstimateGoodsWriteItem from "./EstimateGoodsWriteItem";
import EstimatePrintPopup from "@/components/user/estimate/popup/EstimatePrintPopup";

import LocalStorageManager from "@/LocalStorageManager";
import { getContractDept, escapeRegExp } from "@/utils/spUtils";
import DateTimePicker from "@/components/common/calendar/DateTimePicker";

export default {
  name: "EstimateWrite",
  components: {
    PageWithLayout,
    AlertPopup,
    DateTimePicker,
    TableView,
    Input,
    InputPhone,
    InputNumber,
    InputAutoMoney,
    Fold,
    Board,
    CheckboxItem,
    Textarea,
    Sticky,
    ScorePopup,
    Radio,
    EstimatePrintPopup,
    RequestPartnersInfo,
    AnnouncePartnerPopup,

    CatalogListPopup,
    CatalogDetailPopup,
    ClientStraightPopup,
    EstimateGoodsWriteItem,
  },
  mixins: [WritePageMixin, CreditMixin, PopCatalogMixin, ClientStraightMixin, PageMixin],
  data() {
    return {
      model: new EstimateModel(),
      //goodsModel: EstimateGoodsWriteItemModel,
      itemData: {},
      beforeItemData: {},
      isModifyMode: false,
      isPopPartners: false,
      isAlertCancel: false,
      isTempConfirm: false,
      isSaveConfirm: false,
      isUpdateConfirm: false,
      isRemoveConfirm: false,
      // isTaxApply: false,
      isDiscountApply: false,
      totalSupplyCount: 0,
      totalDiscountCount: 0,
      totalTaxCount: 0,
      quotationPriceTotal: 0,
      // itemDiscountRate: 0,
      priceToKorean: "",
      stringDiscountRate: "",
      isPopEstimatePrint: false,
      estimatePrint: null,
    };
  },
  computed: {
    SpprContents() {
      if (!this.model.SpprContents) return "";
      let target = this.model.SpprContents;

      return escapeRegExp(target);
    },
    toPath() {
      const { upEstimateNum } = this.model;
      const path = this.$routerPath.ESTIMATE_VIEW;
      const ret = makePathWithQuery(path, upEstimateNum, this.$route.query);
      return ret;
    },
  },
  created() {
    this.isModifyMode = Boolean(this.$route.meta.isModifyMode);
    this.model.itemId = this.$route.params.id;
    this.model.title = this.model.reqTitle;

    this.model.setUserData(LocalStorageManager.shared.getUserData());
    const { id } = this.$route.params;
    this.estimateGetData(id);
    //this.getData(id);
  },
  methods: {
    onClickPopEstimatePrint() {
      this.isPopEstimatePrint = true;
    },
    onClickClosePopEstimatePrint() {
      this.isPopEstimatePrint = false;
    },
    async estimateGetBeforeData(id) {
      const path = `${this.$apiPath.ESTIMATE}/${id}`;
      const result = await ApiService.shared.getData(path);

      const { code, data, message } = result;
      if (code !== "200") {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
        return;
      }

      this.estimatePrint = data;
    },
    onUpdateDiscountPrice() {
      // this.totalDiscountCount = this.stringDiscountRate
      //   ? this.totalDiscountCount
      //   : this.model.discountRate * this.totalSupplyCount * 0.01;
      this.totalDiscountCount = Number(this.stringDiscountRate) * this.totalSupplyCount * 0.01;
      return this.totalDiscountCount;
    },
    calculatePrice() {
      if (this.totalDiscountCount == undefined) {
        this.onUpdateDiscountPrice();
      }
      if (this.model.itemDiscountRate) {
        this.quotationPriceTotal =
          this.totalSupplyCount - this.totalDiscountCount + this.totalTaxCount;
      } else {
        this.quotationPriceTotal = this.totalSupplyCount + this.totalTaxCount;
      }
      this.priceToKorean = getMoneyToKorean(this.quotationPriceTotal);
    },
    onUpdateTotalCount() {
      this.totalSupplyCount = this.model.quotationItemList.reduce(
        (accumulator, currentValue, currentIndex) => {
          return accumulator + Number(currentValue.supplyPrice);
        },
        0,
      );
      this.calculatePrice();
    },
    onApplyTax() {
      this.model.taxYn = true;
      this.totalTaxCount = this.isDiscountApply
        ? parseInt((this.totalSupplyCount - this.totalDiscountCount) * 0.1, 10)
        : parseInt(this.totalSupplyCount * 0.1, 10);

      this.calculatePrice();
      // this.model.quotationItemList.forEach((item) => {
      //   item.tax = String(Number(item.supplyPrice) * 0.1);
      // });
      const quotationItemList = this.model.quotationItemList.map((item, index) => {
        return {
          ...item,
          tax: String(Number(item.supplyPrice) * 0.1),
        };
      });
      this.model.quotationItemList = _.cloneDeep(quotationItemList);
    },

    onDisapplyTax() {
      this.model.taxYn = false;
      this.totalTaxCount = 0;

      this.calculatePrice();
      this.model.quotationItemList.forEach((item) => {
        item.tax = "0";
      });
    },
    onApplyDiscount() {
      this.isDiscountApply = true;
      this.model.itemDiscountRate = Number(this.stringDiscountRate);
      this.totalDiscountCount = this.totalSupplyCount * (this.model.itemDiscountRate / 100);
      if (this.model.taxYn) {
        this.totalTaxCount = parseInt((this.totalSupplyCount - this.totalDiscountCount) * 0.1, 10);
      } else {
        this.totalTaxCount = 0;
      }

      this.calculatePrice();
    },
    onDisapplyDiscount() {
      this.isDiscountApply = false;
      this.totalDiscountCount = 0;
      if (this.model.taxYn) {
        this.totalTaxCount = parseInt(this.totalSupplyCount * 0.1, 10);
      } else {
        this.totalTaxCount = 0;
      }

      this.calculatePrice();
    },
    async estimateGetData(id) {
      const path = `${this.$apiPath.ESTIMATE}/${id}`;
      const result = await ApiService.shared.getData(path);

      const { code, data, message } = result;
      if (code !== "200") {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
        return;
      }

      if (data.quotation) {
        this.isDiscountApply = this.model.itemDiscountRate ? true : false;
        this.model.taxYn = data.quotation.taxYn;
        this.model.itemDiscountRate = data.quotation.discountRate;
        this.stringDiscountRate = String(data.quotation.discountRate);
        this.totalSupplyCount = data.quotation.totalSupplyCount;
        this.totalTaxCount = data.quotation.taxTotal;
        this.totalDiscountCount = data.quotation.totalDiscountCount;
        this.quotationPriceTotal = data.quotation.quotationPriceTotal;
        this.priceToKorean = getMoneyToKorean(this.quotationPriceTotal);
      }

      this.isDiscountApply = this.stringDiscountRate ? true : false;
      this.model.taxYn = data.quotation ? data.quotation.taxYn : false;
      this.model.setData({ ...data }); //

      this.estimateGetBeforeData(this.model.upEstimateNum);
    },
    // async getData(path) {
    //   const result = await ApiService.shared.getData(path);
    //   // this.$store.dispatch(ESTIMATE_WRITE_ACTION);
    //   const { code, data, text } = result;
    //   if (code !== '200') {
    //     this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, text);
    //     return;
    //   }
    //   this.model.setData({ ...data });

    //   // const { handleItemNm, quotationSupplierList } = data;

    //   // this.isClientMode = !handleItemNm;

    //   // if (
    //   //   // handleItemNm &&
    //   //   quotationSupplierList &&
    //   //   quotationSupplierList.length > 0
    //   // ) {
    //   //   this.inputSearchText = handleItemNm;

    //   //   this.supplyCompanyDataList = quotationSupplierList;
    //   //   this.checkedNames = this.supplyCompanyDataList.map((item) => item.spplrCompanyRgstNo);
    //   // }
    // },

    // async onClickSave(isTemp) {
    //   const alertMessage = this.model.getAlertMessage();
    //   if (alertMessage) {
    //     this.alert(alertMessage);
    //     return;
    //   }
    //   if (isTemp) {
    //     this.model.status = 'T'; //임시저장
    //   } else {
    //     this.model.status = 'C'; //저장
    //   }
    //   this.saveEstimate();
    // },
    async saveEstimate() {
      const obj = this.model.getData();
      const path = `${this.$apiPath.QUOTATION}`;

      if (this.isModifyMode || this.model.quotation) {
        //수정
        obj.discountRate = Number(this.stringDiscountRate);

        const id = this.model.quotation ? this.model.quotation.quotationNum : this.$route.params;
        const result = await ApiService.shared.putData(`${path}/${id}`, obj);
        const { code, data, text } = result;

        if (code !== "200") {
          this.alert(text);
          return;
        }
      } else {
        //등록

        const result = await ApiService.shared.postData(path, obj);
        const { code, data, text } = result;
        if (code !== "200") {
          this.alert(text);
          return;
        }
      }

      this.goToList();
    },

    onClickPopPartner() {
      this.isPopPartners = true;
    },
    onClickRemoveItem(itemToRemove) {
      this.model.estimatePartnerList = this.model.estimatePartnerList.filter(
        (item) => item !== itemToRemove,
      );
    },
    onClickClosePopPartner() {
      this.isPopPartners = false;
    },
    onClickAddPopPartner(checkedNames) {
      this.model.estimatePartnerList = assign(
        this.model.estimatePartnerList,
        checkedNames,
        "personNum",
      );

      this.isPopPartners = false;
    },
    onClickRemove() {
      const funcRemove = async () => {
        const { itemId } = this.model;
        const path = `${this.$apiPath.ESTIMATE}/${itemId}`;

        const result = await ApiService.shared.deleteData(path);

        const { code, message } = result;

        if (code !== "200") {
          this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);

          this.goToList();

          return;
        }

        this.goToList();
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, {
        text: "견적요청을 삭제하시겠습니까?",
        onClickY: funcRemove,
      });
    },

    onClickGoToList() {
      this.goToList();
    },
    onClickCancelConfirm() {
      this.isAlertCancel = true;
    },
    onClickSaveConfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.isSaveConfirm = true;
      this.model.status = "R";
    },
    onClickUpdateConfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.isUpdateConfirm = true;
      this.model.status = "R";
    },
    onClickTempConfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.isTempConfirm = true;
      this.model.status = "T";
    },
    onClickRemoveConfirm() {
      this.isRemoveConfirm = true;
    },
    onClickAlertCancel() {
      this.isAlertCancel = false;
    },
    onClickCloseAlertConfirm() {
      this.isSaveConfirm = false;
      this.isUpdateConfirm = false;
      this.isTempConfirm = false;
      this.isRemoveConfirm = false;
      this.isAlertCancel = false;
    },
    goToList() {
      this.$router.push(this.$routerPath.ESTIMATE_LIST);
    },

    // onClickedAll(arrCheckedAll) {
    //   if (arrCheckedAll.length > 0) {
    //     this.checkedNames = this.supplyCompanyDataList.map((item) => item.spplrCompanyRgstNo);
    //     this.checkNamesAll = ['allChecked'];
    //   } else {
    //     this.checkNamesAll = [];
    //     this.checkedNames = [];
    //   }
    // },
    // onClickedItem() {
    //   this.checkNamesAll = [];
    //   if (
    //     this.supplyCompanyDataList != undefined &&
    //     this.checkedNames.length === this.supplyCompanyDataList.length
    //   ) {
    //     this.checkNamesAll = ['allChecked'];
    //   } else {
    //     this.checkNamesAll = [];
    //   }
    // },

    // onClickPopScore(rowData) {
    //   const companyNo = rowData.spplrCompanyRgstNo || rowData.clientCompanyRgstNo;
    //   this.getCreditData(companyNo);
    // },

    // onClickSupplySearch() {
    //   if (this.isClientMode) {
    //     this.getClient();
    //     this.searchType = 'client';
    //     return;
    //   }

    //   if (!this.handleItemNm) {
    //     this.alert('품목군을 선택해주세요.');
    //     return;
    //   }

    //   this.getCompanyList(this.handleItemNm);
    //   this.searchType = 'handleItem';
    // },
    // async getClient(strKeyword) {
    //   let urlRest = '';

    //   if (strKeyword) {
    //     const obj = {
    //       clientName: strKeyword,
    //     };

    //     urlRest = makeQueryStringByObject(obj);
    //   }

    //   const path = `${this.$apiPath.COMPANY_CLIENT}${urlRest}`;
    //   const result = await ApiService.shared.getData(path);

    //   const { data, code, message } = result;

    //   if (code !== '200') {
    //     this.alert(message);
    //     return;
    //   }
    //   this.supplyCompanyDataList = data || [];
    //   this.supplyCompanyDataList.map((item) => {
    //     item.clientYn = true;
    //   });

    //   this.model.setHandleItemAndCompanyList(null, []);
    // },
    // async getCompanyList(itemCategoryNm) {
    //   const path = `${this.$apiPath.SUPPLIER_SEARCH}?itemName=${encodeURIComponent(
    //     itemCategoryNm,
    //   )}`;
    //   this.supplyCompanyDataList = [];
    //   const result = await ApiService.shared.getData(path);

    //   const { data, code, message } = result;

    //   if (code !== '200') {
    //     this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
    //     return;
    //   }

    //   this.inputSearchText = itemCategoryNm;

    //   this.checkedNames = [];

    //   this.supplyCompanyDataList = data;

    //   this.model.setHandleItemAndCompanyList(itemCategoryNm, []);
    // },
    // // 품목군 입력하면서 sort.
    // onInputKeywordCategory(value) {
    //   // if(value == ''){
    //   //   this.supplyCompanyDataList = undefined;
    //   // }
    //   this.searchedCategories = this.itemCategoryList.filter((item) =>
    //     item.itemCategoryNm.toUpperCase().includes(value.toUpperCase()),
    //   );
    // },
  },
};
</script>
