import LocalStorageManager from "@/LocalStorageManager";
import { setPhone } from "@/utils/phoneUtils";

export default class EstimateModel {
  constructor() {
    this.itemId = "";
    this.objRaw = {};
    this.estimateNum = "";
    this.userData = LocalStorageManager.shared.getUserData();

    this.status = "T";
    this.reqDate = "";
    this.reqName = "";
    this.SpprName = ""; // 구매사담당자
    this.SpprContents = ""; // 상세내용
    this.name = "";
    this.tel = {
      phone0: "02",
      phone1: "",
      phone2: "",
    };
    this.handleNum = null;
    this.title = ""; //제목
    this.contents = "";
    this.quotationName = ""; //공급사담당자
    this.quotationContents = ""; //비고
    this.quotationItemList = [];
    this.estimateDate = null;
    this.price = "";
    this.discountRate = "";
    this.itemDiscountRate = "";
    this.priceTotal = "";
    this.taxYn = false;
  }
  setUserData(obj) {
    const {
      SpprName, //구매사담당자
      SpprContents, //상세내용
      tel, //전화번호
      username, // 사업자등록번호
      companyName, // 회사명
      email, //대표이메일
    } = obj;
    this.SpprName = SpprName || "";
    this.SpprContents = SpprContents || "";
    this.username = username || "";
    this.companyName = companyName || "";
    this.email = email || "";
    // setPhone(tel, this.tel);
  }
  setData(obj) {
    //수정일때 data SET
    this.objRaw = _.cloneDeep(obj);

    const {
      estimateNum,
      status,
      reqDate,
      reqName,
      name,
      email,
      handleNum,
      title,
      contents,
      estimateDate,
      quotation,
      estimateItemList,
      statusName,
      handleItemNm,
      prchsrMngrNm,
      discountRate,
      itemDiscountRate,
      upEstimateNum,
      upTitle,
      quotationPriceTotal,
      discountTotal,
      taxYn,
      tel,
    } = obj;
    this.estimateNum = estimateNum;
    this.status = status || "T";
    this.reqDate = reqDate || "";
    this.reqName = reqName || this.userData.name;
    this.SpprName = name || this.userData.name;
    // this.name = name || this.userData.name;
    this.handleNum = handleNum;
    this.title = title || "";
    this.reqTitle = title || "";
    this.SpprContents = contents || "";
    // this.estimateItemList = estimateItemList;
    this.quotation = quotation;
    this.quotationItemList = quotation ? quotation.quotationItemList : estimateItemList;
    this.quotationContents = quotation ? quotation.contents : "";
    this.quotationName = quotation ? quotation.name : "";
    this.discountRate = quotation ? quotation.discountRate : itemDiscountRate;
    this.discountTotal = quotation ? quotation.discountTotal : 0;
    this.taxYn = quotation ? quotation.taxYn : false;
    this.quotationPriceTotal = quotation ? quotation.quotationPriceTotal : 0;
    this.statusName = statusName;
    this.handleItemNm = handleItemNm;
    this.prchsrMngrNm = prchsrMngrNm;
    this.itemDiscountRate = quotation ? quotation.discountRate : itemDiscountRate;
    this.upEstimateNum = upEstimateNum || "";
    this.upTitle = upTitle || "";
    setPhone(quotation ? quotation.tel : "", this.tel);
    // this.tel = `${quotation.tel.phone0}-${quotation.tel.phone1}-${quotation.tel.phone2}`;

    //기타 obj 값 추가
  }

  getData() {
    //수정일때 data SET
    let obj = {
      estimateNum: this.estimateNum,
      status: this.status,
      name: this.quotationName, // 공급사담당자
      tel: `${this.tel.phone0}-${this.tel.phone1}-${this.tel.phone2}`,
      handleNum: this.handleNum,
      title: this.title,
      contents: this.quotationContents,
      taxTotal: 10,
      currencyCode: "KRW",
      discountRate: this.itemDiscountRate,
      priceTotal: this.priceTotal,
      email: this.email,
      quotationItemList: this.quotationItemList,
      taxYn: this.taxYn,
      // quotationItemList: [
      //   // 샘플 품목 데이터
      //   {
      //     itemName: this.quotationItemList[0].itemName,
      //     itemCode: this.quotationItemList[0].itemCode, // 실제 존재하는 품목 값
      //     quantity: this.quotationItemList[0].quantity,
      //     quantityCode: 'E',
      //     price: 1000,
      //     standard: '규격',
      //   },
      // ],
    };
    return Object.assign({}, this.objRaw, obj);
  }

  getAlertMessage() {
    if (!this.quotationName) return "파트너사 담당자를 입력해주세요.";
    // if (!this.handleNum) return '품목군을 확인해주세요.';
    if (!this.title) return "견적요청명을 입력해주세요.";
    // if (!this.estimatePartnerList || this.estimatePartnerList.length === 0)
    //   return '파트너사를 선택해주세요.';
    // if (!this.contents) return '상세내용을 입력해주세요.';

    return null;
  }

  // setHandleItemAndCompanyList(handleItemNm, quotationSupplierList) {
  //   this.handleItemNm = handleItemNm;
  //   this.quotationSupplierList = quotationSupplierList;
  // }
}
