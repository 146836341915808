<template>
  <div class="area_print" style="margin-top: 40px">
    <div class="tbl_print tbl_list">
      <table>
        <colgroup>
          <col style="width: 72px" />
          <col style="*" />
          <col style="width: 130px" />
        </colgroup>
        <thead>
          <tr>
            <th>No</th>
            <th>품목명</th>
            <th>수량</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in itemData.estimateItemList" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.itemName }}</td>
            <td>{{ item.quantity }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tbl_print tbl_view" style="margin-top: 40px">
      <table>
        <caption />
        <colgroup>
          <col style="width: 254px" />
          <col style="*" />
        </colgroup>
        <tbody>
          <tr>
            <th>상세내용</th>
            <td v-html="itemData.contents" />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import SumViewMixin from "@/mixins/SumViewMixin";
import { getMoneyToKorean } from "@/utils/stringUtils";

export default {
  mixins: [SumViewMixin],
  props: {
    itemData: Object,
  },
  computed: {
    estimateItemList() {
      const { estimateItemList } = this.itemData;

      return estimateItemList || [];
    },
    isDiscountApply() {
      const { discountRate } = this.itemData;

      return discountRate && discountRate !== 0;
    },
    isTaxApply() {
      const { taxTotal } = this.itemData;

      return taxTotal && taxTotal !== 0;
    },
    taxText() {
      if (this.isTaxApply) {
        return "세액 적용";
      } else {
        return "세액 미적용";
      }
    },
    discountText() {
      if (this.isDiscountApply) {
        return "할인 " + this.itemData.discountRate + "% 적용";
      } else {
        return "할인 미적용";
      }
    },
    TotalSmallText() {
      if (!this.isTaxApply && !this.isDiscountApply) {
        return "";
      } else {
        var discountText = "";
        var taxText = "";
        // if (this.isDiscountApply) {
        //   discountText = '-할인';
        // }
        if (this.isTaxApply) {
          taxText = "+세액";
        }
        const result = taxText;
        return result;
      }
    },
  },
  methods: {
    getMoneyToKorean(value) {
      return getMoneyToKorean(value);
    },
  },
};
</script>
