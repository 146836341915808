<template>
  <div class="area_print">
    <div class="tbl_print tbl_view">
      <table>
        <caption />
        <colgroup>
          <col style="width: 216px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>비고</th>
            <td class="td_text" v-html="etcData" />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrintEtc",
  props: {
    etcData: String,
  },
};
</script>
