<template>
  <div class="area_print">
    <div class="tbl_print tbl_list">
      <table>
        <colgroup>
          <col style="width: 72px" />
          <col style="width: 240px" />
          <col style="width: 130px" />
          <col style="width: 130px" />
          <col style="width: 130px" />
          <col style="width: 130px" />
        </colgroup>
        <thead>
          <tr>
            <th>No</th>
            <th>품목명</th>
            <th>수량</th>
            <th class="align_right">단가</th>
            <th class="align_right">공급가액</th>
            <th class="align_right">세액</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in itemData.quotationItemList" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.itemName }}</td>
            <td>{{ item.quantity | currency }}</td>
            <td class="align_right">
              {{ item.price | currency }}
            </td>
            <td class="align_right">
              {{ item.supplyPrice | currency }}
            </td>
            <td class="align_right">
              {{ item.tax }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tbl_print tbl_view">
      <table>
        <caption />
        <colgroup>
          <col style="width: 375px" />
          <col style="width: 619px" />
        </colgroup>
        <tbody>
          <tr>
            <td class="align_top">
              <p>- {{ taxText }}</p>
              <p>- {{ discountText }}</p>
            </td>
            <td class="no_padding">
              <dl class="list_total">
                <dt>공급가액</dt>
                <dd>{{ itemData.supplyPriceTotal | currency }}</dd>
                <dt>할인</dt>
                <dd>(-) {{ itemData.discountTotal }}</dd>
                <dt>세액</dt>
                <dd>(+) {{ itemData.taxTotal | currency }}</dd>
                <dt class="dt_total">
                  견적금액
                  <span class="txt_small">(공급가액{{ TotalSmallText }})</span>
                </dt>
                <dd class="dd_total">
                  <span class="txt_total">일금</span>
                  <span class="txt_korean">{{
                    getMoneyToKorean(itemData.quotationPriceTotal)
                  }}</span>
                  <span class="txt_price">({{ itemData.quotationPriceTotal | currency }})</span>
                </dd>
              </dl>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import SumViewMixin from "@/mixins/SumViewMixin";
import { getMoneyToKorean } from "@/utils/stringUtils";

export default {
  mixins: [SumViewMixin],
  props: {
    itemData: Object,
  },
  computed: {
    estimateItemList() {
      const { estimateItemList } = this.itemData;

      return estimateItemList || [];
    },
    isDiscountApply() {
      const { discountRate } = this.itemData;

      return discountRate && discountRate !== 0;
    },
    isTaxApply() {
      const { taxTotal } = this.itemData;

      return taxTotal && taxTotal !== 0;
    },
    taxText() {
      if (this.isTaxApply) {
        return "세액 적용";
      } else {
        return "세액 미적용";
      }
    },
    discountText() {
      if (this.isDiscountApply) {
        return "할인 " + this.itemData.discountRate + "% 적용";
      } else {
        return "할인 미적용";
      }
    },
    TotalSmallText() {
      if (!this.isTaxApply && !this.isDiscountApply) {
        return "";
      } else {
        let discountText = "";
        let taxText = "";
        // if (this.isDiscountApply) {
        //   discountText = '-할인';
        // }
        if (this.isTaxApply) {
          taxText = "+세액";
        }
        const result = taxText;
        return result;
      }
    },
  },
  methods: {
    getMoneyToKorean(value) {
      return getMoneyToKorean(value);
    },
  },
};
</script>
