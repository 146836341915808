import { getCanConvertNumber } from "@/utils/numberUtils";

const SumViewMixin = {
  computed: {
    isDiscountApply() {
      const { discountRate } = this.itemData;

      if (!discountRate) return false;

      return discountRate !== "0";
    },
    isTaxApply() {
      const { taxPriceSum } = this.itemData;

      if (!taxPriceSum) return false;

      return taxPriceSum !== "0";
    },
    sum() {
      const { spplrPriceSum, taxPriceSum } = this.itemData;

      if (!getCanConvertNumber(spplrPriceSum)) return 0;
      if (!getCanConvertNumber(taxPriceSum)) return 0;

      const nSpplrPriceSum = parseInt(spplrPriceSum);
      const nTaxPriceSum = parseInt(taxPriceSum);

      const ret = nSpplrPriceSum - this.discountPriceTotal + nTaxPriceSum;
      return ret;
    },
    discountPriceTotal() {
      if (!this.isDiscountApply) return 0;

      const { discountRate, spplrPriceSum } = this.itemData;

      if (!discountRate) return 0;
      if (!spplrPriceSum) return 0;

      if (!getCanConvertNumber(discountRate)) return 0;

      const nDiscountRate = parseFloat(discountRate);

      const discountRateFixed = nDiscountRate.toFixed(1);

      const result = spplrPriceSum * (discountRateFixed / 100);

      return parseInt(result);
    },
    discountTotal() {
      const { discountRate, spplrPriceSum } = this.itemData;

      if (!discountRate || !spplrPriceSum) return 0;

      const nDiscountRate = parseFloat(discountRate);

      const discountRateFixed = nDiscountRate.toFixed(1);

      const result = spplrPriceSum * (discountRateFixed / 100);

      return parseInt(result);
    },
  },
};
export default SumViewMixin;
